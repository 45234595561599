import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  navigate(e) {
    e.stopPropagation();

    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      window.open(this.linkTarget.href, "_blank");
    } else {
      Turbo.visit(this.linkTarget.href);
    }
  }
}
